import React, { useEffect, useState } from 'react';
import '../../css/hero/title.css';

//Banner Iamge
import aventisLogo from '../../img/aventislogo.png';

const Title = () => {
    const textData = "Aventis";
    const textBio = " Learn-2-Earn";

    // Function to create spans with random delay
    const createSpansWithRandomDelays = (text) => {
        return text.split(' ').join(' \u00A0 ').split('').map((char, index) => {
            const delay = Math.random() * 2;
            return { char, key: `char-${index}`, delay };
        });
    };

    const [spans, setSpans] = useState([]);
    const [bioSpans, setBioSpans] = useState([])

    useEffect(() => {
        setSpans(createSpansWithRandomDelays(textData));
    }, []);

    useEffect(() => {
        setBioSpans(createSpansWithRandomDelays(textBio));
    }, [])

    return (
        <div className='caption-container'>
            <div className='groupImageandLiner'>
            <img src={aventisLogo}/>
            <div className="sub-caption" aria-label={textBio}>
                <div className='main-caption'>
                    {bioSpans.map(({ char, key, delay }) => (
                        <span
                            key={key}
                            className="animated-element"
                            aria-hidden="true"
                            style={{ animationDelay: `${delay}s` }}
                        >
                            {char}
                        </span>
                    ))}
                </div>
            </div>
            </div>
        </div>
    );
};

export default Title;