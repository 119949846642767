//Import React Stuff
import React, { useState, useEffect } from 'react';

//Import CSS
import '../../css/dashboard/dashboard.css'

//Local Imports
import CardContainerLB from './scrollWheel/cardContainerLB';
import CardContainerLP from './scrollWheel/cardContainerLP';

//Import Trade Learning Pass
import TradeLearningPassNFT from '../popup/tradeLeaerningPassNFT';

const UserDashboard = ({ _props }) => {

    const web3 = _props.web3;

    const UserWalletAddress = _props.UserWalletAddress;

    const [openPage, SetOpenPage] = useState(false);

    const closePopup = () => {
        SetOpenPage(false)
    }

    const openPopup = () => {
        SetOpenPage(true)
    }

    useEffect(() => {
        if (!openPage) {
            // Actions to take when openPage is false, if any
        }
    }, [openPage]);  // This effect depends on openPage and runs when it changes
    

    return (
        <div className="dashboard-container">
            {openPage && <TradeLearningPassNFT isOpen={openPage} onClose={closePopup} userWallet={UserWalletAddress} _web3={web3}/>}
            <div className="badge-row">
                <h1 className='list-descriptors'>Learning Badge</h1>
            </div>
            <div className="card-container-row">
                <CardContainerLB userWallet={UserWalletAddress} _web3={web3}/>
            </div>
            <br />
            <div className='badge-row'>
                <h1 className='list-descriptors'>Learning Pass</h1>
                <button className='list-adder' onClick={(e)=>{openPopup()}}>+</button>
            </div>
            <div className='card-container-row'>
                <CardContainerLP userWallet={UserWalletAddress} _web3={web3}/>
            </div>
        </div>
    )
}

export default UserDashboard;