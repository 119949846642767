import React, { useRef, useState, useEffect } from 'react';

// Import CSS
import '../../../css/courses/cardContainer.css';

// Local Imports
import VideoCard from '../videoCard/videoCard'; // Make sure the component is correctly named
import VideoPopup from '../videoInterface/videoInterface';
import UseLearningPassNFT from '../../popup/useLearningPassNFT';

// Import Thumbnail
import thumbnail from '../../../img/videoCovers/csCoverArt.png';
import thumbnail2 from '../../../img/videoCovers/csCoverArt2.png';
import thumbnail3 from '../../../img/videoCovers/csCoverArt3.png';

//Import Hooks
import useIsWhitelisted from '../../../customhooks/nftFunctions/useCheckWhitelist';

const CardContainerCS = ({ _videoInformation, UserWalletAddress, _web3 }) => {
    const [videoOpen, setVideoOpen] = useState(false);
    const [signupOpen, setSignupOpen] = useState(false);
    const [currentVideoInfo, setCurrentVideoInfo] = useState();
    const [listOfVideos, setListOfVideos] = useState(_videoInformation);
    const [interactionCount, setInteractionCount] = useState(0); // Adding a counter state
    const [refreshToken, setRefreshToken] = useState(0);


    const { isWhitelisted, isLoading } = useIsWhitelisted(
        currentVideoInfo ? currentVideoInfo.address : undefined,
        UserWalletAddress,
        _web3
    );

    const containerRef = useRef(null);

    // Handle the wheel event for horizontal scrolling

    const handleWheel = (e) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += e.deltaY;
        }
    };

    function handleRefreshTokenAdd() {
        setRefreshToken(currentToken => currentToken + 1);
    }

    //Function Randomly Return Thumbnail

    const randomThumbnail = () => {
        let arrayOfThumbnail = [thumbnail, thumbnail2, thumbnail3];
        // Generate a random index based on the length of the arrayOfThumbnail
        let randomIndex = Math.floor(Math.random() * arrayOfThumbnail.length);
        // Return the thumbnail at the randomly generated index
        return arrayOfThumbnail[randomIndex];
    };

// Use useEffect to handle the opening and closing logic based on whitelist status
useEffect(() => {
    const delay = 500; // Delay in milliseconds (e.g., 2000 milliseconds = 2 seconds)

    if (isWhitelisted !== undefined && currentVideoInfo !== undefined) {
        const timer = setTimeout(() => {
            if (isWhitelisted) {
                console.log("Account is Whitelisted");
                setVideoOpen(true);
                setSignupOpen(false);
            } else {
                console.log("Account is not Whitelisted");
                setSignupOpen(true);
                setVideoOpen(false);
            }
        }, delay);

        // Cleanup function to clear the timeout if the component unmounts or the dependencies change before the timeout is completed
        return () => clearTimeout(timer);
    }
}, [isWhitelisted, currentVideoInfo, interactionCount]); // Dependencies to re-run this effect


const handleVideoInterface = (_metaData) => {
    if(!_web3){
        window.alert("Log In First!")
        return;
    };

    if(videoOpen) {
        return;
    }

    setCurrentVideoInfo(_metaData); // Trigger re-check of whitelist status in useEffect
    setInteractionCount(prev => prev + 1); // Increment counter on each interaction
};

const handleVideoInterfaceClosing = () => {
    setVideoOpen(false);
    setCurrentVideoInfo(undefined);
};

const handleCloseSignup = () => {
    setSignupOpen(false);
};

useEffect(() => {
    setListOfVideos(_videoInformation);
    console.log("Updated video information:", _videoInformation);
}, [_videoInformation]);

useEffect(() => {
    console.log(currentVideoInfo);
}, [currentVideoInfo]);

    return (
        <div ref={containerRef} onWheel={handleWheel} className="card-container">
            {signupOpen && <UseLearningPassNFT handler={handleCloseSignup} UserWalletAddress={UserWalletAddress} _contractAddress={currentVideoInfo ? currentVideoInfo.address : undefined} _web3={_web3} videoTitle={currentVideoInfo ? currentVideoInfo.title : undefined} videoDescription={currentVideoInfo ? currentVideoInfo.bio : undefined} refreshHandler={handleRefreshTokenAdd}/>
            }
            {videoOpen && <VideoPopup onClose={handleVideoInterfaceClosing} _metadata={currentVideoInfo}/>
            }
            {listOfVideos.filter(card => card.course === "cs").map((card, index) => (
                <VideoCard 
                    key={index} 
                    title={card.title} 
                    description={card.bio} 
                    thumbnail={randomThumbnail()} 
                    metaData={card} 
                    handler={handleVideoInterface} 
                />
            ))}
        </div>
    );
}

export default CardContainerCS;
