import { useState, useEffect, useMemo } from 'react';
import { learningPassABI } from '../../../abi/learningPassABI';

const useFetchLearningPass = (account, _web3) => {
  const [learningPassDetails, setTokenDetails] = useState(null);
  const [tokenloading, setLoading] = useState(false);
  const [tokenerror, setError] = useState(null);

  // Use a static tokenId for fetching
  const tokenId = 0;

  // useMemo to create and return a memoized contract instance
  const contract = useMemo(() => {
    const contractAddress = learningPassABI[0].networks["137"].address; // Using network ID 137 for example
    return new _web3.eth.Contract(learningPassABI[0].abi, contractAddress);
  }, []);

  useEffect(() => {
    const fetchlearningPassDetails = async () => {
      if (!contract || !account) return;

      setLoading(true);
      setError(null);

      try {
        const balance = await contract.methods.balanceOf(account, tokenId).call();
        const details = {
          tokenId,
          balance: parseInt(balance, 10),
          rarity: 'Learning Pass' // Static rarity name for tokenId 0
        };

        setTokenDetails(details);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchlearningPassDetails();
  }, [account]); // Depend on account and contract instance

  return { learningPassDetails, tokenloading, tokenerror };
};

export default useFetchLearningPass;
