import { useState } from 'react';

// Import ABI and Contract Address
import { learningPassABI } from '../../../abi/learningPassABI';

const useLearningPassApproval = (operatorAddress, _web3, UserWalletAddress) => {
    const [approvalLoading, setLoading] = useState(false);
    const [approvalError, setError] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const [ownerAddress, setOwnerAddress] = useState(UserWalletAddress)

    const checkAndSetApproval = async () => {
        setLoading(true);
        setError('');

        try {
            const contract = new _web3.eth.Contract(
                learningPassABI[0].abi, 
                learningPassABI[0].networks["137"].address
            );

            // Check current approval status
            const currentApproval = await contract.methods.isApprovedForAll(ownerAddress, operatorAddress).call();
            setIsApproved(currentApproval);

            if (!currentApproval) {
                // Set approval if not currently approved

                const gasPrice = await _web3.eth.getGasPrice(); // Dynamically obtain gas price
                const updatedGasPrice = parseInt(gasPrice) + 5 * 1e9; // Increase the gas price to speed up the transaction

                const gasEstimate = await contract.methods.setApprovalForAll(operatorAddress, true).estimateGas({ from: ownerAddress });
                // It's important to estimate gas to avoid transaction failures due to out of gas exceptions


                await contract.methods.setApprovalForAll(operatorAddress, true)
                    .send({ from: ownerAddress, gas : gasEstimate, gasPrice: updatedGasPrice })
                    .on('transactionHash', hash => {
                        console.log('Transaction hash:', hash);
                    })
                    .on('receipt', receipt => {
                        console.log('Transaction receipt:', receipt);
                        setIsApproved(true);
                    })
                    .on('error', (error, receipt) => {
                        console.error('Failed to approve:', error);
                        setError('Failed to approve.');
                        setLoading(false);
                    });
            }
            setLoading(false);
        } catch (error) {
            console.error('Error checking or setting approval:', error);
            setError(error.message);
            setLoading(false);
        }
    };

    return { checkAndSetApproval, approvalLoading, approvalError, isApproved };
};

export default useLearningPassApproval;
