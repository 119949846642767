import { useEffect, useState } from 'react';
import Web3 from 'web3';

const useIsWhitelisted = (contractAddress, userAddress, _web3, refreshToken) => {
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkWhitelisted = async () => {

            if (!window.ethereum) {
                console.error('Ethereum object not found, install MetaMask.');
                return;
            }
            if (!contractAddress || !userAddress) {
                setIsWhitelisted(false);
                return;
            };
            if (!_web3) return;

            try {
                // ABI for isWhitelisted function
                const abi = [{
                    "constant": true,
                    "inputs": [{"name": "_user", "type": "address"}],
                    "name": "isWhitelisted",
                    "outputs": [{"name": "", "type": "bool"}],
                    "type": "function"
                }];

                // Create contract instance
                const contract = new _web3.eth.Contract(abi, contractAddress);

                // Call the isWhitelisted function
                const result = await contract.methods.isWhitelisted(userAddress).call();
                setIsWhitelisted(result);
            } catch (error) {
                console.error('Error checking if user is whitelisted:', error);
            } finally {
                setIsLoading(false);
            }
        };

        checkWhitelisted();
    }, [contractAddress, userAddress, _web3, refreshToken]);

    return { isWhitelisted, isLoading };
};

export default useIsWhitelisted;
