import React from 'react';
import '../../../css/courses/videoCard.css'

function VideocCard({ title, description, thumbnail, handler, metaData }) {

    return (
        <div className="clickable-card" onClick={(e) => { handler(metaData) }}>
            <div className="card-front">
                <img src={thumbnail} alt="Description" />
            </div>
            <div className="card-back">
                <h3>{title}</h3>
            </div>
        </div>
    );
}

export default VideocCard;
