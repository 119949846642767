import React from 'react';
import '../../../css/dashboard/card/card.css'; // Make sure to create a corresponding CSS file

import commonlogo from '../../../img/nftAssets/BadgeNFTCommon-ezgif.com-optimize.gif'; // Ensure the logo path is correct
import uncommonlogo from '../../../img/nftAssets/BadgeNFTUncommon-ezgif.com-optimize.gif';
import rarelogo from "../../../img/nftAssets/BadgeNFTRare-ezgif.com-optimize.gif";
import epiclogo from "../../../img/nftAssets/BadgeNFTEpic-ezgif.com-optimize.gif";
import legendary from '../../../img/nftAssets/BadgeNFTLegend-ezgif.com-optimize.gif';
import learningpass from "../../../img/nftAssets/LearningPassNFT-ezgif.com-optimize.gif";

const Card = ({ title, description, balanceOf }) => {

  let videoImage = "";
  let openSeaURL = '';

  switch (description) {
    case "Common":
      videoImage = commonlogo;
      openSeaURL = "https://opensea.io/assets/matic/0x18c1b8ca66f4a72fb27c50e02a6c91d9bb3d78f0/0";
      break;
    case "Uncommon":
      videoImage = uncommonlogo;
      openSeaURL = "https://opensea.io/assets/matic/0x18c1b8ca66f4a72fb27c50e02a6c91d9bb3d78f0/1";
      break;
    case "Rare":
      videoImage = rarelogo;
      openSeaURL = "https://opensea.io/assets/matic/0x18c1b8ca66f4a72fb27c50e02a6c91d9bb3d78f0/2";
      break;
    case "Epic":
      videoImage = epiclogo;
      openSeaURL = "https://opensea.io/assets/matic/0x18c1b8ca66f4a72fb27c50e02a6c91d9bb3d78f0/3";
      break;
    case "Legendary":
      videoImage = legendary;
      openSeaURL = "https://opensea.io/assets/matic/0x18c1b8ca66f4a72fb27c50e02a6c91d9bb3d78f0/4";
      break;
    case "Learning Pass":
      videoImage = learningpass;
      openSeaURL = "https://opensea.io/assets/matic/0x5243e0338a56905cd7da2edb974a07e38531636a/0";
      break;
  }

  //Card for Badges
  if(description != "Learning Pass"){
    return (
      <a href={openSeaURL} target='_blank'>
        <div className="card">
          <img src={videoImage} />
          <p>Rarity : {description}</p>
          <p>Balance : {balanceOf}</p>
        </div>
      </a>
    );
  } else {
    return (
      <a href={openSeaURL} target='_blank'>
        <div className="card">
          <img src={videoImage} />
          <p>Balance : {balanceOf}</p>
        </div>
      </a>
    );
  }
};

export default Card;
