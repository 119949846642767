import { useState } from 'react';
import Web3 from 'web3';

// Import ABI and Contract Address
// Assume contract ABI is stored in an external file or directly as a JSON object in your project
import { learningPassTransactionABI } from '../../../abi/learningPassTransactionABI';

const useSwapTokens = (_web3, UserWalletAddress) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');    

    // The contract method to swap tokens
    const swapTokens = async (tokenId) => {
        try {
            setLoading(true);

            // Create the contract instance
            const tokenContract = new _web3.eth.Contract(
                learningPassTransactionABI[0].abi, 
                learningPassTransactionABI[0].networks["137"].address
            );

            console.log(tokenContract);

            const gasPrice = await _web3.eth.getGasPrice(); // Dynamically obtain gas price
            const updatedGasPrice = parseInt(gasPrice) + 5 * 1e9; // Increase the gas price to speed up the transaction

            const gasEstimate = await tokenContract.methods.swapTokens(tokenId).estimateGas({ from: UserWalletAddress });

            // Call the swapTokens function
            await tokenContract.methods.swapTokens(tokenId)
                .send({ from: UserWalletAddress, gas: gasEstimate, gasPrice: updatedGasPrice })
                .on('transactionHash', function(hash){
                    console.log("Transaction hash:", hash);
                })
                .on('receipt', function(receipt){
                    console.log("Transaction receipt: ", receipt);
                })
                .on('error', function(error, receipt) {
                    console.error("Transaction failed: ", error);
                    setError("Transaction failed.");
                    setLoading(false);
                });

            setLoading(false);
            return true; // Optional: Return true on successful transaction
        } catch (err) {
            console.error("Error while swapping tokens: ", err.message);
            setError(err.message);
            setLoading(false);
            return false; // Optional: Return false on error
        }
    };

    return { swapTokens, loading, error };
};

export default useSwapTokens;
