import { useState } from 'react';
import axios from 'axios';

const useFetchVideo = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const url = 'https://amdapp-backendv2-pbaud.kinsta.app/video'

    const fetchVideo = async (videoId) => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(url, { videoid: videoId });
            setData(response.data); // Axios automatically handles converting JSON data
        } catch (err) {
            setError(err.response ? err.response.data.Message : err.message);
        } finally {
            setLoading(false);
        }
    };

    return { fetchVideo, data, loading, error };
};

export default useFetchVideo;
