import { useState } from 'react';
import Web3 from 'web3';

// Import ABI and Contract Address
import { whitelistNFTABI } from '../../../abi/whiteListNFTABI';

const useWhitelistMe = (_web3, contractAddress, userWalletAddress) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const whitelistMe = async () => {
        try {
            setLoading(true);

            // Create the contract instance
            const whitelistContract = new _web3.eth.Contract(
                whitelistNFTABI[0].abi,
                contractAddress
            );

            const gasPrice = await _web3.eth.getGasPrice();
            const updatedGasPrice = parseInt(gasPrice) + 5 * 1e9; // Optionally increase the gas price

            const gasEstimate = await whitelistContract.methods.whitelistMe().estimateGas({ from: userWalletAddress });

            // Call the whitelistMe function
            await whitelistContract.methods.whitelistMe()
                .send({ from: userWalletAddress, gas: gasEstimate, gasPrice: updatedGasPrice })
                .on('transactionHash', function(hash){
                    console.log("Transaction hash:", hash);
                })
                .on('receipt', function(receipt){
                    console.log("Transaction receipt: ", receipt);
                })
                .on('error', function(error, receipt) {
                    console.error("Transaction failed: ", error);
                    setError("Transaction failed.");
                    setLoading(false);
                });

            setLoading(false);
            return true;
        } catch (err) {
            console.error("Error while attempting to whitelist: ", err.message);
            setError(err.message);
            setLoading(false);
            return false;
        }
    };

    return { whitelistMe, loading, error };
};

export default useWhitelistMe;
