import React, { useEffect, useRef } from 'react';
import Card from '../card/card.js'; // Import the Card component
import '../../../css/dashboard/scrollWheel.css'; // Make sure to create a corresponding CSS file

//Import Hooks
import useFetchLearningPass from '../../../customhooks/nft/useLearningPassTokenDetails.js';

const CardContainerLP = ({ userWallet, _web3 }) => {

  const { learningPassDetails, tokenloading, tokenerror } = useFetchLearningPass(userWallet, _web3);

  const containerRef = useRef(null);

  const handleWheel = (e) => {
    containerRef.current.scrollLeft += e.deltaY;
  };

  if (tokenloading) return <div>Loading...</div>; // Loading state
  if (tokenerror) return <div>Error: {tokenerror.message}</div>; // Error state

  return (
    <div ref={containerRef} onWheel={handleWheel} className="card-container">
        <Card title={"Learning Badge"} balanceOf={!learningPassDetails ? "No Content" : learningPassDetails.balance} description={!learningPassDetails ? "No Content" : learningPassDetails.rarity} />
    </div>
  );
};

export default CardContainerLP;