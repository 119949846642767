import React, { useState } from 'react';
import '../../css/popup/useLearningPassNFT.css'; // Importing the CSS file for styles

// Local Hook Import
import useWhitelistMe from '../../customhooks/nftFunctions/useWhitelistMe';
import useLearningPassApproval from '../../customhooks/nftFunctions/useLearningPassApproval';

// Image Import
import learningpass from '../../img/nftAssets/LearningPassNFT-ezgif.com-optimize.gif';

function UseLearningPassNFT({ handler, UserWalletAddress, _contractAddress, _web3, videoTitle, videoDescription, refreshHandler }) {
    const [userWallet, setUserWallet] = useState(UserWalletAddress);
    const [contractAddress, setContractAddress] = useState(_contractAddress);

    const { checkAndSetApproval, approvalLoading, approvalError, isApproved } = useLearningPassApproval(contractAddress, _web3, userWallet);
    const { whitelistMe, loading, error } = useWhitelistMe(_web3, contractAddress, UserWalletAddress);

    console.log(isApproved);

    const handleSubmit = () => {
        if (!isApproved) {
            checkAndSetApproval();
        } else {
            whitelistMe();
            refreshHandler();
            handler();
        }
    };

    return (
        <div className="popup">
            <div className="header" style={{ position: 'sticky', top: 0, backgroundColor: 'transparent' }}>
                <button onClick={handler} className="closeButton">X</button>
            </div>
            <div className="body-content">
                <div className="content">
                    <img src={learningpass} alt="Learning Pass NFT" className="image" />
                    <p>Use a learning pass to get access to this video forever?</p>
                </div>
                <div className="video-content">
                    <h3>Title: {videoTitle}</h3>
                    <p style={{textAlign : "left", overflowWrap : 'break-word', wordWrap : "break-word"}}>Description:</p>
                    <p style={{
                       width: '100%',          // Ensures the paragraph uses the full width of its parent
                       textAlign: 'left',      // Aligns text to the left
                       overflowWrap: 'break-word', // Allows words to break and wrap to the next line
                       wordWrap: 'break-word', // Ensures older browsers handle wrapping correctly
                       whiteSpace: 'normal'    // Ensures text wraps naturally
                        }}>{videoDescription}</p>
                </div>
            </div>
            <button onClick={handleSubmit} className="signupButton">Sign up</button>
        </div>
    );
}

export default UseLearningPassNFT;
