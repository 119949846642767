// ErrorPopup.js

import React, { useState, useEffect } from 'react';
import '../../css/popup/devNotes.css';

import illustration from '../../img/heroAssests/Phase1Illustration.png';

const DevNotes = ({ showPopup } ) => {
  const [isVisible, setIsVisible] = useState(showPopup);

  useEffect(() => {
    setIsVisible(showPopup);
  }, [showPopup]);

  const handleAcknowledge = () => {
    setIsVisible(false);
    // You can add additional logic here if needed
    // For example, reload the screen.
  };

  let content = `
  Our dApp featuring Learn2Earn (L2E) capabilities is now available in beta. A heartfelt thank you to our dedicated community for your ongoing support and enthusiasm. The initial distribution of Learning Pass NFT has been completed successfully, with the conversion of 30,000 AVTM into one Learning Pass NFT during our recent campaign. 
  `

  let reachOut = `
  If you encounter any issues or have questions, please contact our admin team on Telegram or Discord. Your feedback is crucial to our ongoing development!

  `

  return (
    <div className={`error-popup ${isVisible ? 'visible' : ''}`}>
      <div className="popup-content">
      <img className='illustation' src={illustration}/>
        <h1>Developer Notes</h1>
        <p><b><eugene id='eugene'>Hi I am Eugene.</eugene> </b>I am the Blockchain Developer at Aventis</p>
        <mark><p>
            {content}
            </p>,
            <br/>
            <p>
              {reachOut}
            </p>
        <p>For more information, do read our whitepaper : <p></p><a href='https://avtm.gitbook.io/whitepaper/about-avtm/how-it-works/pillar-2-affordability' id='linkurl'>https://avtm.gitbook.io/whitepaper/about-avtm/how-it-works/pillar-2-affordability</a></p></mark>
        <br></br><br></br><button id="okbtn"onClick={handleAcknowledge}>OK</button>
      </div>
    </div>
  );
};

export default DevNotes;