import React, { useState } from 'react';
import '../../css/popup/switchNetwork.css';  // Ensure this CSS path is correct

const NetworkPopup = ({ isVisible }) => {
    const [showPopup, setShowPopup] = useState(isVisible);

    const switchToPolygonMainnet = async () => {
        try {
            if (window.ethereum) {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x89' }],  // 0x89 is the chainId for Polygon Mainnet
                });
                setShowPopup(false);  // Automatically close the popup on success
            } else {
                alert("Ethereum object doesn't exist!");
                setShowPopup(false);  // Close the popup if there is no Ethereum provider
            }
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: '0x89',
                            chainName: 'Polygon Mainnet',
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'MATIC',  // 2-6 characters long
                                decimals: 18,
                            },
                            rpcUrls: ['https://polygon-rpc.com/'],  // Main RPC URL for Polygon
                            blockExplorerUrls: ['https://polygonscan.com/'],  // Main block explorer for Polygon
                        }],
                    });
                    setShowPopup(false);  // Close the popup after adding the network
                } catch (addError) {
                    alert('Failed to add the Polygon Mainnet: ' + addError.message);
                    setShowPopup(false);  // Close the popup even if adding the network fails
                }
            } else {
                alert('Failed to switch to the Polygon Mainnet: ' + switchError.message);
                setShowPopup(false);  // Close the popup if switching the network fails
            }
        }
    };

    if (!showPopup) return null;

    return (
        <div className="popup">
            <div className="popup_inner">
                <h1>Switch to Polygon Mainnet</h1>
                <p>Please press the button to switch networks.</p>
                <button onClick={switchToPolygonMainnet} className="switchButton">Switch Network</button>
            </div>
        </div>
    );
};

export default NetworkPopup;
