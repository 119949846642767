import React, { useRef, useEffect, useState } from 'react';
import '../../../css/courses/videoInterface.css'; // Make sure CSS is properly linked

// Local Import
import useFetchVideo from '../../../customhooks/back-end/useGetVideoLink';

const VideoPopup = ({ _metadata, videoSrc, description, onClose }) => {
    const videoRef = useRef(null);

    const { fetchVideo, data, loading, error } = useFetchVideo();
    const [videoAPI, setVideoAPI] = useState(null);

    console.log(data);

    // Fetch video data when videoid changes
    useEffect(() => {
        if (_metadata && _metadata.videoid) {
            fetchVideo(_metadata.videoid);
        }
    }, [_metadata.videoid]);  // Dependency on _metadata.videoid

    // Update videoAPI state when data changes
    useEffect(() => {
        if (data) {
            setVideoAPI(data[0]); // Assuming data[0] contains the video data you want
        }
    }, [data]); // Dependency on data

    return (
        <div className="video-popup">
            <div className="video-container">
                <video ref={videoRef} src={videoAPI ? videoAPI.link : videoSrc} controls autoPlay />
            </div>
            <div className="info-container">
                <div className="description">
                    <p>{description}</p>
                </div>
            </div>
            <div className="button-container">
                <button onClick={onClose} className="close-btn">X</button>
            </div>
        </div>
    );
};

export default VideoPopup;
