import { useState, useEffect } from 'react';
import axios from 'axios';

const useVideos = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get('https://amdapp-backendv2-pbaud.kinsta.app/videos') // Update the URL to the path where your server is hosted
            .then(response => {
                setVideos(response.data); // Assuming the server response format is an array of videos
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, []); // The empty array ensures this effect only runs once upon mount

    return { videos, loading, error };
};

export default useVideos;
