//Import React Stuff
import React , {useState} from 'react';

//Local Imports
import Title from '../hero/title';
import CardContainerW3 from './courseSliders/CardContainerW3';
import CardContainerAI from './courseSliders/CardContainerAI';
import CardContainerCS from './courseSliders/CardContainerCS';

//Import CSS
import '../../css/courses/courses.css';

//Import Hooks
import useVideos from '../../customhooks/back-end/useGetVideo';

//Banenr Image
import banenrIamge from '../../img/heroAssests/Phase1Illustration.png';

const Courses = ({ UserWalletAddress, _web3 }) => {

    const { videos, loading, error } = useVideos();

    return (
        <div className='dashboard-container'>
            <Title/>
            <div className='badge-row'>
                <h1 className='list-descriptors'>Web-3</h1>
            </div>
            <div className='card-container-row'>
                <CardContainerW3 _videoInformation={videos} UserWalletAddress={UserWalletAddress} _web3={_web3} />
            </div>
            <div className='badge-row'>
                <h1 className='list-descriptors'>Artificial Inteligence</h1>
            </div>
            <div className='card-container-row'>
                <CardContainerAI _videoInformation={videos} UserWalletAddress={UserWalletAddress} _web3={_web3}/>
            </div>
            <div className='badge-row'>
                <h1 className='list-descriptors'>Cyber Security</h1>
            </div>
            <div className='card-container-row'>
                <CardContainerCS _videoInformation={videos} UserWalletAddress={UserWalletAddress} _web3={_web3}/>
            </div>
        </div>
    )
}

export default Courses;