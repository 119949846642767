import React, { useState, useEffect } from 'react';
import '../../css/popup/tradeLearningPassNFT.css';

// Import Hooks
import useSwapTokens from '../../customhooks/nftFunctions/useLearningPassTransaction';

import useFetchNftRarities from '../../customhooks/nft/useLearningBadgeTokenDetails';
import useLearningPassApproval from '../../customhooks/nftFunctions/useLearningBadgeApproval';

//Import ABI
import { learningPassTransactionABI } from '../../../abi/learningPassTransactionABI';

//Import Image
import logo from "../../img/nftAssets/LearningPassNFT-ezgif.com-optimize.gif";

function TradeLearningPassNFT({ isOpen, onClose, userWallet, _web3 }) {
    const [selectedOption, setSelectedOption] = useState('');

    let tokenIds = [0,1,2,3,4];

    //Hook Information
    const { swapTokens, loading, error } = useSwapTokens(_web3, userWallet);
    const { checkAndSetApproval, approvalLoading, approvalError, isApproved } = useLearningPassApproval(learningPassTransactionABI[0].networks["137"].address, _web3, userWallet);
    const { tokenDetails, learningBadgeloading, learningBadgeerror } = useFetchNftRarities(userWallet, tokenIds, _web3);

    // This state will hold the filtered tokens
    const [filteredTokens, setFilteredTokens] = useState([]);

    // Effect hook to update filteredTokens based on tokenDetailsArray
    useEffect(() => {
        if (!learningBadgeloading && tokenDetails) {
            const validTokens = tokenDetails.filter(token => token.balance > 5);
            setFilteredTokens(validTokens);
        }
    }, [tokenDetails, learningBadgeloading]);  // Depend on tokenDetailsArray and dataloading

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(`Selected Option: Token ID ${selectedOption} with ${filteredTokens.find(token => token.tokenId.toString() === selectedOption)?.rarity} rarity`);
        if(isApproved){
            swapTokens(selectedOption);
        } else {
            checkAndSetApproval();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="popup-form">
            <div className="form-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>Add Learning Pass</h2>
                <img className="image-content" src={logo} alt="Placeholder" />
                <p>Eligible Tokens Count: {filteredTokens.length}</p>
                {filteredTokens.length > 0 ? (
                    <form onSubmit={handleSubmit}>
                        <label>
                            Choose an option:
                            <select value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                                <option value="">Select...</option>
                                {filteredTokens.map((token) => (
                                    <option key={token.tokenId} value={token.tokenId.toString()}>
                                        {`${token.rarity}`}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                ) : (
                    <p>No tokens meet the criteria.</p>
                )}
            </div>
        </div>
    );
}

export default TradeLearningPassNFT;
