import React, { Component } from 'react';

//Local Imports
import Background from './components/scripts/hero/background';
import Navbar from './components/scripts/navbar/navbar';
import NetworkPopup from './components/scripts/popup/switchNetwork';
import UserDashboard from './components/scripts/dashboard/dashboard';
import Courses from './components/scripts/courses/courses';
import DevNotes from './components/scripts/popup/devNotes';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      web3: null,
      pageLoad: false,
      UserWalletAddress: "",
      isPolygon: true,
      networkPrompt: false,
      currentPage : "Courses"
    };
    this.web3HandleChange = this.web3HandleChange.bind(this);
    this.accountAddressChange = this.accountAddressChange.bind(this);
    this.setIsPolygon = this.setIsPolygon.bind(this);
    this.closeNetworkSwitch = this.closeNetworkSwitch.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isPolygon && !this.state.networkPrompt) {
      this.setState({ networkPrompt: true });
    }
  }

  web3HandleChange(_web3) {
    this.setState({ web3: _web3 });
  }

  accountAddressChange(_address) {
    this.setState({ UserWalletAddress: _address });
  }

  setIsPolygon(_isPolygon) {
    this.setState({ isPolygon: _isPolygon });
  }

  closeNetworkSwitch() {
    this.setState({ networkPrompt: false });
  }

  changePage(_page) {
    this.setState({currentPage : _page});
    console.log("Current Page : " + this.state.currentPage);
  }

  render() {

    return (
      <div className="app-container">
        <DevNotes showPopup={true}/>
        <Background />
        <Navbar handler={this.web3HandleChange} accountHandler={this.accountAddressChange} networkHandler={this.setIsPolygon} pageHandler={this.changePage}/>
        {this.state.networkPrompt && <NetworkPopup isVisible={this.state.networkPrompt} onClose={this.closeNetworkSwitch}/>}
        {this.state.currentPage === "Dashboard" && <UserDashboard _props={this.state}/>}
        {this.state.currentPage === "Courses" && <Courses UserWalletAddress={this.state.UserWalletAddress} _web3={this.state.web3}/>}
      </div>
    );
  }
}

export default App;
