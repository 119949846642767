import React, { useRef } from 'react';
import Card from '../card/card.js'; // Import the Card component
import '../../../css/dashboard/scrollWheel.css'; // Make sure to create a corresponding CSS file

//Import Hooks
import useFetchNftRarities from '../../../customhooks/nft/useLearningBadgeTokenDetails.js';

const CardContainerLB = ({ userWallet, _web3 }) => {

    const tokenIds = [0, 1, 2, 3, 4]; // Example token IDs

    const { tokenDetails, learningBadgeloading, learningBadgeerror } = useFetchNftRarities(userWallet, tokenIds, _web3);

    const containerRef = useRef(null);

    const handleWheel = (e) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += e.deltaY;
        }
    };

    if (learningBadgeloading) return <div>Loading...</div>; // Loading state
    if (learningBadgeerror) return <div>Error: {learningBadgeerror.message}</div>; // Error state

    return (
        <div ref={containerRef} onWheel={handleWheel} className="card-container">
            {tokenDetails && tokenDetails.map((card, index) => (
                <Card key={index} title={"Learning Badge"} description={card.rarity} balanceOf={card.balance} />
            ))}
        </div>
    );
};

export default CardContainerLB;