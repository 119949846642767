import { useState, useEffect, useMemo } from 'react';
import Web3 from 'web3';
import { learningBadgeABI } from '../../../abi/learningBadgeABI';  // Assume the ABI is stored here

const useFetchNftRarities = (account, tokenIds, _web3) => {
  const [tokenDetails, setTokenDetails] = useState([]);
  const [learningBadgeloading, setLoading] = useState(false);
  const [learningBadgeerror, setError] = useState(null);

  // Rarity mapping as an array of objects
  const rarityMapping = [
    { id: 0, name: 'Common' },
    { id: 1, name: 'Uncommon' },
    { id: 2, name: 'Rare' },
    { id: 3, name: 'Epic' },
    { id: 4, name: 'Legendary' }
  ];

  // useMemo to create and return a memoized contract instance
  const contract = useMemo(() => {
    const contractAddress = learningBadgeABI[0].networks["137"].address;  // Using network ID 137 for example
    return new _web3.eth.Contract(learningBadgeABI[0].abi, contractAddress);
  }, []);

  useEffect(() => {
    const fetchTokenDetails = async () => {
      if (!contract || !account || !tokenIds.length) return;

      setLoading(true);
      setError(null);
      let detailsList = [];

      try {
        const promises = tokenIds.map(async (tokenId) => {
          const balance = await contract.methods.balanceOf(account, tokenId).call();
          const rarity = rarityMapping[tokenId].name; // Retrieve the rarity name using the tokenId as an index

          return { tokenId, balance: parseInt(balance, 10), rarity };
        });

        const results = await Promise.all(promises);
        detailsList = results; // Store the results directly into the list

        setTokenDetails(detailsList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenDetails();
  }, [account]);  // Ensure this effect runs when these values change

  return { tokenDetails, learningBadgeloading, learningBadgeerror };
};

export default useFetchNftRarities;
